import { useState } from "react";

export default function Datenschutz () {

    const [hidden, setHidden] = useState(true);

    const handleClick = () => {
        setHidden(!hidden);
        document.querySelector(".datenschutz-button").classList.toggle("datenschutz-button-active");
        window.scrollTo(0,document.body.scrollHeight);
    }

    return (
        <div>
            <button className="datenschutz-button" onClick={handleClick} >Datenschutzerklärung</button>
            <div className="datenschutz-text" hidden={hidden}>
                    <h2>Datenschutz</h2> 
                    <p>
                        Die Betreiber dieser Seiten nehmen
                    den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                    vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                    Datenschutzerkl&auml;rung.
                    </p> 
                    <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                    personenbezogener Daten m&ouml;glich. Soweit auf unseren Seiten personenbezogene Daten
                    (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit
                    m&ouml;glich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdr&uuml;ckliche Zustimmung
                    nicht an Dritte weitergegeben.
                    </p> 
                    <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
                    (z.B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
                    Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
                    </p>
                    <p>
                        &nbsp;
                    </p> 
                    <h2>Server-Log-Files</h2> 
                    <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                    Server-Log Files, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:
                    </p> 
                    <ul> 
                    <li>
                    Browsertyp und Browserversion
                    </li> 
                    <li>
                        verwendetes Betriebssystem
                    </li> 
                    <li>
                        Referrer URL
                    </li> 
                    <li>
                    Hostname des zugreifenden Rechners
                    </li> 
                    <li>
                        Uhrzeit der Serveranfrage
                    </li> 
                    </ul> 
                    <p>
                        Diese Daten sind
                    nicht bestimmten Personen zuordenbar. Eine Zusammenf&uuml;hrung dieser Daten mit anderen
                    Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachtr&auml;glich zu
                    pr&uuml;fen, wenn uns konkrete Anhaltspunkte f&uuml;r eine rechtswidrige Nutzung bekannt werden.</p>
                    <p>&nbsp;</p> <h2>Kontaktformular</h2> <p>Wenn Sie uns per Kontaktformular Anfragen zukommen
                    lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
                    Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns
                    gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p><p>&nbsp;</p> <h2>
                    Newsletterdaten</h2> <p>Wenn Sie den auf der Webseite angebotenen Newsletter beziehen
                    m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die
                    &Uuml;berpr&uuml;fung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit
                    dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten
                    verwenden wir ausschlie&szlig;lich f&uuml;r den Versand der angeforderten Informationen und geben sie
                    nicht an Dritte weiter.</p> <p>Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa
                    &uuml;ber den "Austragen"-Link im Newsletter.</p><p>&nbsp;</p> <h2>Google Analytics</h2> <p>Diese
                    Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600
                    Amphitheatre Parkway Mountain View, CA 94043, USA.</p> <p>Google Analytics verwendet so genannte
                    &quot;Cookies&quot;. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine
                    Analyse der Benutzung der Website durch Sie erm&ouml;glichen. Die durch den Cookie erzeugten
                    Informationen &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen Server von Google
                    in den USA &uuml;bertragen und dort gespeichert.</p> <p>Mehr Informationen zum Umgang mit
                    Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerkl&auml;rung von Google: <a
                    href="https://support.google.com/analytics/answer/6004245?hl=de">
                    https://support.google.com/analytics/answer/6004245?hl=de</a></p> <p><strong>Browser
                    Plugin</strong></p> <p>Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende
                    Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen
                    k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch den Cookie erzeugten und
                    auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung
                    dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                    https://tools.google.com/dlpage/gaoptout?hl=de</a></p> <p><strong>Widerspruch gegen
                    Datenerfassung</strong></p> <p>Sie k&ouml;nnen die Erfassung Ihrer Daten durch Google Analytics
                    verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung
                    Ihrer Daten bei zuk&uuml;nftigen Besuchen dieser Website verhindert: 
                    <button href="javascript:gaOptout();">
                        Google Analytics deaktivieren
                    </button>
                    </p><p>&nbsp;</p> <h2>SSL-Verschl&uuml;sselung</h2> <p>Diese Seite nutzt aus Gr&uuml;nden der
                    Sicherheit und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschl&uuml;sselung. Eine verschl&uuml;sselte
                    Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &quot;http://&quot; auf
                    &quot;https://&quot; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p> <p>Wenn die SSL
                    Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p><p>&nbsp;</p> <h2>Recht auf Auskunft, L&ouml;schung, Sperrung</h2>
                    <p>Sie haben jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
                    personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung
                    sowie ein Recht auf Berichtigung, Sperrung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren
                    Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im Impressum
                    angegebenen Adresse an uns wenden.</p><p>&nbsp;</p> <h2>Widerspruch Werbe-Mails</h2> <p>Der
                    Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur
                    &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird
                    hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im
                    Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p><p>
                    &nbsp;</p> <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
            </div>
        </div>
    )
}