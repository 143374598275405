
import f from '../pictures/about/pic-f.webp'
import j from '../pictures/about/pic-j.webp'
import l from '../pictures/about/pic-l.webp'
import o from '../pictures/about/pic-o.webp'
import jo from '../pictures/about/pic-j-o.webp'
import schild from '../pictures/about/pic-schild.png'


export default function Picturegrid () {
    return (
        <div data-aos="fade-left" data-aos-delay="300" className='picture-grid-container picture-grid-row'>
            <div className="picture-grid-column">
                <img src={f} alt="Familie" className='picture-grid-image'/>
                <img src={j} alt="Kinder" className='picture-grid-image' />
            </div>
            <div className="picture-grid-column">
                <img src={l} alt="Kinder" className='picture-grid-image' />
                <img src={o} alt="Kinder" className='picture-grid-image' />
            </div>
            <div className="picture-grid-column">
                <img src={jo} alt="Familie" className='picture-grid-image'/>
                <img src={schild} alt="Hier"  className='picture-grid-image'/>
            </div>
        </div>
    )
}


