import 'bootstrap/dist/css/bootstrap.css';
import Picturegrid from './picturegrid';


// About the firm tabs with cards
export default function About ()
{
   

    return (
        <div id="betrieb" className="about-container">
            <div className='about-text-container' data-aos="fade-up">
                <div className="about-header">Über uns</div>
                <div className='about-text'>
                    Wir sind ein kleiner <strong>Familienbetrieb</strong> und bewirtschaften unsere 
                    Felder nach den <strong>Richtlinien des Ökolandbaus</strong>, seit 2013 sind wir ein 
                    zertifizierter Bioland Betrieb. 
                    Neben dem Anbau von Getreide (Dinkel, Braugerste, Roggen, Emmer) für 
                    Biobrauereien und Mühlen, bauen wir auch <strong>Frühkartoffeln, Kartoffeln und 
                    Feldgemüse</strong> an. <br /> <br />

                    In unserem Verkaufskammerl erhalten sie <strong>ganzjährig</strong> Kartoffeln und ab 
                    Herbst auch Karotten, Zwiebeln, Kürbisse und Kraut. Unser Kammerl ist 
                    jeden Tag <strong>rund um die Uhr</strong> geöffnet.
                </div>
            </div>
            <Picturegrid/>
        </div>
    );
}