import { useState } from "react";

export default function Impressum () {

    const [hidden, setHidden] = useState(true);

    const handleClick = () => {
        setHidden(!hidden);
        document.querySelector(".impressum-button").classList.toggle("impressum-button-active");
        window.scrollTo(0,document.body.scrollHeight);
    }

    return (
        <>
            <button className="impressum-button" onClick={handleClick} >Impressum</button>
            <div className="impressum-text" hidden={hidden}>
                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Dieter Wittmann<br />
                Biohof Wittmann<br />
                Holzgasse 14<br />
                91790 Raitenbuch</p>

                <h2>Kontakt</h2>
                <p>Telefon: 09147/9465936<br />
                Telefax: 09147/9465935<br />
                E-Mail: info@biohof-wittmann.de</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
            </div>
        </>
    )
}