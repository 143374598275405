import 'bootstrap/dist/css/bootstrap.css';

import { SocialIcon } from 'react-social-icons';
import Datenschutz from './datenschutz';
import Impressum from './impressum';

import zertbioland from '../pictures/zertifikate/Zert_Bioland_2024.PNG'
import zertabcert from '../pictures/zertifikate/Zert_ABCERT_2024.PNG'

// Footer with socials and location map
export default function Footer ()
{
    return(
        <footer id = 'kontakt' className=' footer'>
            <section >
                 
                 <div className='footer-icons '>
                      <SocialIcon className='footer-icon grow ' url='https://instagram.com/biolandhof_wittmann?igshid=YmMyMTA2M2Y=' target="_blank"/>
                      <SocialIcon className='footer-icon grow ' url='https://twitter.com/bioland_de' target="_blank"/>
                      <SocialIcon className='footer-icon grow ' url='https://www.tiktok.com/tag/bioland' target="_blank"/>
                      <SocialIcon className='footer-icon grow ' url='mailto:info@biohof-wittmann.de' target="_blank"/>
                      <SocialIcon className='footer-icon grow ' url='' target="_blank"/>
                  </div>
  
                  <div className="container">
                      <div className="row">
                          <div className='col-sm-12 col-md-6 col-lg-6  footer-title'>
                          <strong>Kontakt</strong> <br />
                          <ul className='footer-text'>
                              <li>Holzgasse 14 , 91790 Raitenbuch</li>
                              <li>Telefon: 09147/9465936</li>
                              <li>Email: info@biohof-wittmann.de</li>
                          </ul>
                          <strong>Öffnungszeiten</strong> <br />
                          <ul className='footer-text'>
                              <li>Unser Verkaufskammerl ist jeden Tag rund um die Uhr geöffnet</li>
                          </ul>
                          </div>
          
                          {/* <div id="map-container-google-2" className='z-depth-1-half map-container col-sm-12 col-md-6 col-lg-6'>
                              <iframe 
                                  title='map' 
                                  src="https://maps.google.com/maps?q=Holzgasse%2014&t=k&z=19&ie=UTF8&iwloc=&output=embed" 
                                  frameBorder="0"
                                  className='map'>
                              </iframe>
                          </div> */}

                          <div className='col-sm-12 col-md-6 col-lg-6 footer-zert-container'>
                              <a href="https://www.biolandhof-wittmann.de/BilderHomepage/Zert_Bioland_2024.pdf" target='_blank' rel="noreferrer"><img className='footer-zert' src={zertbioland} alt="Zert_Bioland"/></a>
                              <a href="https://www.biolandhof-wittmann.de/BilderHomepage/Zert_ABCERT_2024.pdf" target='_blank' rel="noreferrer"><img className='footer-zert' src={zertabcert} alt="Zert_ABCERT" /></a>
                              <div className='footer-zert-num'>DE-ÖKO-006</div>
                          </div>
                          
                      </div>
                      
                  </div>
                  
              </section>
             <section className='footer-lastSection'>
                 <div > <strong>© Copyright 2022</strong> Biohof Wittmann</div>
                 <Impressum/>
                 <Datenschutz/>
             </section>
        </footer>
    );
}