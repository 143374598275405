import './App.css';

import { useEffect } from 'react';

import Header from './components/navbar';
import Carousel from './components/carousel';
import Footer from './components/footer';
import Items from './components/items';

import { ScrollSpy } from 'bootstrap';
import About from './components/about';

import Aos from "aos"
import "aos/dist/aos.css"



function App() {

  // Bootstrap ScrollSpy init
  useEffect(() => {
    var scrollSpy = new ScrollSpy(document.body, {
      target: '#navbar'
    })

    Aos.init({duration: 2000})

  }, []);

  return (
    <div id='top' className="App">
      <Header/> 
      <Carousel/>
      <About data-aos="fade-up"/>
      <Items data-aos="fade-up"/>
      <Footer data-aos="fade-up"/>
    </div>
  );
}

export default App;
