import { useEffect } from 'react'
import { ReactComponent as LOGO} from '../logo.svg'

import { Container, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';


// Page navigation bar
export default function Navigation(){

    useEffect(() => {
        /* When the user scrolls down, hide the navbar. 
        When the user scrolls up, show the navbar from https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp */
        var prevScrollpos = window.pageYOffset;
        window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("nav").style.top = "0";
        } else {
            document.getElementById("nav").style.top = "-150px";
        }
        prevScrollpos = currentScrollPos;
        }
    })

    return (
    <>
        <Navbar id='nav' className='nav-color' sticky='top' collapseOnSelect expand="lg"  variant="dark">
            <Container id='cont'>
            <Navbar.Brand  href="#top">
            <LOGO
            width="60"
            height="60"
            className="d-inline-block align-top nav-logo"
            />
            {' '}
                
            </Navbar.Brand>
            <div className='nav-brand'>Biolandhof Wittmann</div>
            
            <div className='navbar-image-container'>

                    <a href="https://www.bioland.de" target='_blank' rel='noreferrer'><img id='imageOne' className='navbar-image' src="https://www.bioland.de/typo3conf/ext/conlabz_bioland_theme/Resources/Public/build/img/logo.png" alt="Bioland" /></a> 
                    <a href="https://www.stmelf.bayern.de/mam/cms01/agrarpolitik/bilder/foerderprogramm-1-flaeche.jpg" target='_blank' rel="noreferrer"><img id='imageThree' className='navbar-image' src="https://cdn.pixabay.com/photo/2013/07/13/01/09/european-union-155207_960_720.png" alt="EU" /></a>
                    
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            
            <Navbar.Collapse id="responsive-navbar-nav">
                
                <Nav id='navbar' className='nav-items'>
                
                    <Nav.Link href="#top">Aktuelles</Nav.Link>
                    <Nav.Link href="#betrieb">Über uns</Nav.Link> 
                    <Nav.Link href="#produkte">Produkte</Nav.Link>
                    <Nav.Link href="#kontakt">Kontakt</Nav.Link>
                
                </Nav>
                
            </Navbar.Collapse>

            </Container>
        </Navbar>
    </>
    )
}


