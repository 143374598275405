
import { Carousel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';

import pictureOne from '../pictures/pic-di-bu.webp'
import pictureTwo from '../pictures/pic-kart.webp'
import pictureFeld from '../pictures/pic-feld.webp'
import pictureBio from '../pictures/pic-bio.webp'

// Image slider for recent activity
export default function carousel ()
{
    return (
        <Carousel className="carousel">
            
            
            <Carousel.Item>
                <img
                className="d-block w-100 carousel-image"
                src= "https://www.biolandhof-wittmann.de/BilderHomepage/IMG_20180702_153356.jpg"
                alt="Slide six"
                />
                
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 carousel-image"
                src={pictureBio}
                alt="Slide One"
                />
                <Carousel.Caption className="carousel-text">
                <h3>Alles Bio!</h3>
                <p>Wir achten auf uns <strong>UND</strong> unsere Umwelt </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 carousel-image"
                src={pictureFeld}
                alt="Slide three"
                />
                <Carousel.Caption>
                <h3>Die Natur <strong>fühlen</strong></h3>
                <p></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 carousel-image"
                src={pictureOne}
                alt="Slide four"
                />
                <Carousel.Caption>
                <h3>Landwirtschaft aus <strong>Leidenschaft</strong></h3>
                <p></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 carousel-image"
                src={pictureTwo}
                alt="Slide five"
                />
                <Carousel.Caption>
                    <h3>Kartoffelernte</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>
            
        </Carousel>
    );
}