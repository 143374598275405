import { Card, Popover, Overlay } from "react-bootstrap";

import { useState, useEffect } from "react";

import 'bootstrap/dist/css/bootstrap.css';

import { AiFillInfoCircle } from "react-icons/ai";

import pumpkin from '../pictures/items/k195188rbiss.webp';
import karotte from '../pictures/items/karotten.webp';
import kraut from '../pictures/items/wei195159kraut.webp';
import ofenkart from '../pictures/items/ofenkart.jpeg';
import eier from '../pictures/items/eier.jpeg';
import mehl from '../pictures/items/mehl-type630.jpeg'
import vollkornmehl from '../pictures/items/mehl-vollkorn.jpeg'
import mehlkorn from '../pictures/items/mehl-koerner.jpeg'

// Products | Basically only card items with table for price view
export default function Items ()
{   
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)  

         return () => {
             window.removeEventListener("scroll", handleScroll)
         }
    })

    const handleScroll = () => {
        setShowInfo(false);
    }

    const popover = (
        <Popover id="popover-basic">
          <Popover.Body>
            Waren sind <strong>ausschließlich</strong> durch Selbstbedienung in unserem <strong>Verkaufskammerl</strong> erhältlich ... <br /> <br />
            Lieferung der Produkte ist nicht möglich.
          </Popover.Body>
        </Popover>
      );

    const popoverTarget = document.querySelector('.items-info-icon')

    const handleEvent = () => {
        setShowInfo(!showInfo);
    }


    return(
        <div data-aos="fade-up" id="produkte" className="container">
            <div className="items-header ">
                 Unsere <strong>Produkte</strong>
                <AiFillInfoCircle  onClick={handleEvent}  className="grow items-info-icon "/> 
                <Overlay target={popoverTarget} show={showInfo } placement="bottom">
                {popover}
                </Overlay>
            </div>
            <div id="rowItems" className="row ">
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <Card className="items-item grow"  >
                        <div className="items-item-container">
                            <Card.Img variant="top" src="https://images.unsplash.com/photo-1590165482129-1b8b27698780?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cmVkJTIwcG90YXRvfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" />
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                    <Card.Body>
                                    <Card.Title><strong>Bio Kartoffeln Ditta</strong></Card.Title>
                                    <Card.Text className="items-item-text">
                                        Frische Bio Kartoffeln Sorte-Ditta 
                                    </Card.Text>
                                
                                    </Card.Body>  
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow" >
                    <div className="items-item-container">
                        <Card.Img className="items-item-image" variant="top" src="https://cdn.pixabay.com/photo/2017/02/19/02/40/potatoes-2078775_960_720.jpg" />
                        <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                            <div>
                            <Card.Body>
                                <Card.Title><strong>Bio Kartoffeln Laura</strong></Card.Title>
                                <Card.Text className="items-item-text">
                                    Frische Bio Kartoffeln Sorte-Laura
                                </Card.Text>
                                
                            </Card.Body> 
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow"  >
                        <div className="items-item-container">
                        <Card.Img className="items-item-image" variant="top" src={pumpkin}/>
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                    <Card.Body>
                                        <Card.Title><strong>Bio Kürbis</strong><span className="badge bg-secondary">Saisonal</span></Card.Title>
                                        <Card.Text className="items-item-text">
                                            Saisonaler Bio Kürbis
                                        </Card.Text>
                                        
                                    </Card.Body> 
                                </div>
                            </div>
                        </div>
                    </Card>
            </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow"  >
                        <div className="items-item-container">
                        <Card.Img className="items-item-image" variant="top" src="https://images.unsplash.com/photo-1570980457205-f54f8167650b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80" />
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                    <Card.Body>
                                        <Card.Title><strong>Bio Speisezwiebeln</strong></Card.Title>
                                        <Card.Text className="items-item-text">
                                            Frische Bio Speisezwiebeln
                                        </Card.Text>
                                    
                                    </Card.Body> 
                                </div>
                            </div>
                        </div>
                    </Card>
            </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow"  >
                    <div className="items-item-container">
                    <Card.Img className="items-item-image" variant="top" src={karotte} />
                        <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                            <div>
                                <Card.Body>
                                    <Card.Title><strong>Bio Karotten</strong></Card.Title>
                                    <Card.Text className="items-item-text">
                                        Frische Bio Karotten 
                                    </Card.Text>
                                </Card.Body>
                            </div>
                        </div>
                    </div>
                </Card>   
            </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <Card className="items-item grow"  >
                        <div className="items-item-container">
                        <Card.Img className="items-item-image" variant="top" src={ofenkart} />
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                <Card.Body>
                                    <Card.Title><strong>Bio Ofen-Kartoffeln</strong> <span className="badge bg-secondary">Neu</span> </Card.Title>
                                    <Card.Text className="items-item-text">
                                        Frische Bio Ofen-Kartoffeln 
                                    </Card.Text>
                                </Card.Body>
                                </div>
                            </div>
                        </div>
                    </Card> 
                </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow"  >
                        <div className="items-item-container">
                        <Card.Img className="items-item-image" variant="top" src={kraut}/>
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                    <Card.Body>
                                        <Card.Title><strong>Bio Weißkraut</strong><span className="badge bg-secondary">Saisonal</span></Card.Title>
                                        <Card.Text className="items-item-text">
                                            Saisonales Bio Weißkraut
                                        </Card.Text>
                                        
                                    </Card.Body> 
                                </div>
                            </div>
                        </div>
                    </Card>
            </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow"  >
                        <div className="items-item-container">
                        <Card.Img className="items-item-image image-fit-eier" variant="top" src={eier}/>
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                    <Card.Body>
                                        <Card.Title><strong>Bio Eier</strong></Card.Title>
                                        <Card.Text className="items-item-text">
                                            Frische Bio Eier von glücklichen Hühnern
                                        </Card.Text>
                                        
                                    </Card.Body> 
                                </div>
                            </div>
                        </div>
                    </Card>
            </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow"  >
                        <div className="items-item-container">
                        <Card.Img className="items-item-image image-fit-mehl" variant="top" src={mehl}/>
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                    <Card.Body>
                                        <Card.Title><strong>Bio Ur-Dinkel Type 630</strong></Card.Title>
                                        <Card.Text className="items-item-text">
                                            Bio Ur-Dinkel Type 630
                                        </Card.Text>
                                        
                                    </Card.Body> 
                                </div>
                            </div>
                        </div>
                    </Card>
            </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow"  >
                        <div className="items-item-container">
                        <Card.Img className="items-item-image image-fit-mehl" variant="top" src={vollkornmehl}/>
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                    <Card.Body>
                                        <Card.Title><strong>Bio Ur-Dinkel Vollkornmehl</strong></Card.Title>
                                        <Card.Text className="items-item-text">
                                            Bio Vollkornmehl vom eigenen Ur-Dinkel
                                        </Card.Text>
                                        
                                    </Card.Body> 
                                </div>
                            </div>
                        </div>
                    </Card>
            </div>
            <div data-aos="fade-up" className="col-lg-4 col-md-6 col-sm-12 col-12">
                <Card className="items-item grow"  >
                        <div className="items-item-container">
                        <Card.Img className="items-item-image image-fit-mehl" variant="top" src={mehlkorn}/>
                            <div data-aos="fade" data-aos-delay="250" className="items-item-description"> 
                                <div>
                                    <Card.Body>
                                        <Card.Title><strong>Bio Ur-Dinkel Körner</strong></Card.Title>
                                        <Card.Text className="items-item-text">
                                            Bio Ur-Dinkel Körner
                                        </Card.Text>
                                        
                                    </Card.Body> 
                                </div>
                            </div>
                        </div>
                    </Card>
            </div>
            </div>
        </div>
    )
}